.moreIconMobile {
  color: var(--palette-common-white);
}

.moreIcon {
  color: var(--palette-text-secondary);
}

.actionButton:hover .moreHorizIcon {
  color: #4c5561 !important;
  transition: all ease-in-out 0.3s;
}

.actionButton:hover a {
  color: #4c5561 !important;
}
