.container {
  display: flex;
  background-color: var(--palette-app-background-navy);
  border-radius: 10px;
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 8px;
  margin-bottom: 24px;
}

.image {
  padding-right: 8px;
}

.textContainer {
  padding-top: 8px;
  padding-bottom: 16px;
}

.headingText {
  color: var(--palette-common-white);
  font-size: 15.5px;
  font-weight: 700;
  padding-bottom: 8px;
}

.bodyText {
  color: var(--palette-common-white);
  font-size: 13.3px;
  line-height: 17.29px;
  padding-bottom: 8px;
}

.noteText {
  color: var(--palette-common-white);
  font-size: 11.1px;
}
