.titleContainer {
  padding: 16px 40px 0px 40px !important;
  align-items: baseline !important;
}

.titleText {
  padding-bottom: 20px;
  margin-bottom: 0 !important;
}

.dialogContent {
  padding: 0px 40px 24px 40px !important;
}

@media (max-width: 768px) {
  .dialogContent {
    font-size: 14px;
  }
}

.dialogActions {
  padding: 0px 40px 56px 40px !important;
}

.listContainer {
  padding-top: 20px;
  padding-left: 16px;
}

.list {
  padding-bottom: 20px;
}
