.drawer {
  width: 507px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.formContainer {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.titleContainer {
  color: var(--palette-text-primary);
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
}

.visitTypeTitle {
  color: var(--palette-text-primary);
}

.text {
  color: var(--palette-text-primary);
  font-size: 16px;
}

.smallText {
  color: var(--palette-text-secondary);
  font-size: 13.3px;
}

.closeIcon {
  width: 24px;
  height: 24px;
  color: var(--palette-text-secondary);
}

.deleteMemberIcon {
  width: 24px;
  height: 24px;
  color: var(--palette-text-secondary);
  margin-right: 8px;
}

.currentDateContainer {
  display: flex;
  padding-bottom: 16px;
  padding-top: 16px;
}

.currentDateContainerSelectDate {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
}

.iconContainer {
  display: flex;
}

.drawerIcons {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: var(--palette-text-secondary);
}

.scheduleVisitTabIcon {
  color: var(--palette-text-secondary) !important;
  margin-right: 10px;
}

.scheduleVisitTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scheduleVisitTitlePadding {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
}

.scheduleAnotherMember {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.scheduleVisitTabIconDateTimeContainer {
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.groupIconTextContainer {
  display: flex;
  justify-content: space-between;
  background-color: #e3f4dd;
  margin-left: 10px;
  padding: 0px 5px;
}

.groupIconTextContainerFullGroup {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  padding: 0px 5px;
  background-color: #fff2f8;
}

.groupIconContainer {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.groupIcon {
  margin-right: 3px;
}

.section {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.sectionEdit {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 8px;
}

.sectionContainer {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-direction: column;
}

.sectionContainerRow {
  display: flex;
  padding-top: 8px;
  padding-bottom: 100px;
  flex-direction: row;
}

.sectionHeading {
  display: flex;
  font-size: 14px;
}

.numberOfMembersHeading {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.sectionTitle {
  color: var(--palette-text-secondary);
  font-size: 13.3px;
}

.radioGroup {
  padding-left: 28px;
}

.radioLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radioLabel p {
  padding-right: 4px;
}

.providerTextField {
  width: 80%;
  margin-right: 12px;
  height: 48px !important;
}

.providerInput {
  width: 100%;
}

.providerMenuItem {
  width: 100%;
}

.profileContainer {
  border-bottom: none;
  text-decoration: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileInfo {
  display: flex;
  text-align: unset;
  flex-direction: column;
  color: var(--palette-text-primary);
  width: 100%;
  padding-left: 10px;
}

.profileTitle {
  color: var(--palette-text-primary);
  font-weight: bold;
}

.profileSubTitle {
  display: block;
  color: var(--palette-text-secondary);
}

.ctmProfileAndTabContainer {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr auto;
}

.providerContainerInTabsRow {
  display: flex;
  align-items: center;
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: center;
  width: 100%;
}

.tab {
  margin: 4px !important;
  width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border: 1px solid var(--palette-secondary-main) !important;
  color: var(--palette-secondary-main) !important;
  background-color: var(--palette-app-background-bluePastelAlt) !important;
}

.tabSelected {
  margin: 4px !important;
  border: none;
  color: var(--palette-common-white) !important;
  background-color: var(--palette-secondary-main) !important;
}

.tabLabel {
  padding-top: 0px;
  padding-bottom: 0px;
}

.tabLabelSelected {
  font-weight: bold !important;
}

.badge {
  font-size: var(--typography-body2-font-size);
  margin-left: 8px;
  background-color: var(--palette-text-strokes);
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px;
  color: var(--palette-text-primary);
}

.optionLabel {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.restrictText {
  color: var(--palette-text-secondary);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.checkbox {
  padding-left: 0px;
}

.textField {
  background-color: var(--palette-common-white) !important;
  border-radius: 6px;
  min-height: 36px;
}

.standardHeightTextField {
  height: 40px;
}

.timeTextField {
  width: 110px;
  font-size: 13.3px;
  padding: 0px;
}

.timezoneTextField {
  padding-left: 8px;
  width: 160px;
}

.to {
  padding-left: 8px;
  padding-right: 8px;
}

.visitTypeContainer {
  display: flex;
  flex-direction: row;
}

.visitTypeGroupText {
  padding-left: 8px;
  color: var(--palette-text-primary);
}

.visitTypeOptionText {
  color: var(--palette-text-primary);
}

.visitTypeCheckbox {
  padding-left: 0px !important;
  color: var(--palette-text-secondary);
}

.listItemFocused {
  background-color: rgba(0, 0, 0, 0.08);
}

.confirmSchedule {
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.confirmCheckbox {
  padding: 0px 9px 0px 0px !important;
}

.comboBox {
  padding-top: 16px;
  padding-bottom: 8px;
  width: 100%;
  padding-right: 8px;
  padding-left: 24px;
}

.comboBoxScheduleVisitTab {
  padding-bottom: 8px;
  width: 100%;
  padding-right: 8px;
  padding-left: 0px;
}

.visitReasonTextBox {
  border: 1px solid var(--palette-medium-dark-gray);
  border-radius: 10px;
}

.cancelReasonTextBox {
  border: 1px solid var(--palette-medium-dark-gray);
  border-radius: 10px;
  margin-top: 12px;
}

.chipContainer {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 8px 24px;
}

.tooltip {
  background-color: var(--palette-app-background-main);
  fill: var(--palette-text-link);
  border-radius: 4;
  color: var(--palette-text-secondary);
  font-size: var(--typography-body2-font-size);
}

.numberInputRow {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px;
}

.numberInput {
  width: 64px;
  height: 36px;
  margin-right: 16px;
  border-radius: 6px;
}

.option {
  padding-left: 2px !important;
  background-color: var(--palette-common-white) !important;
}

.buttonContainer {
  height: 92px;
  position: fixed;
  padding-top: 16px;
  padding-bottom: 16px;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid var(--palette-text-strokes);
}

.createUpdateButton {
  margin-left: 0px;
  margin-right: 16px;
}

.cancelButton {
  color: var(--palette-text-link);
  text-decoration: underline;
}

.cancelButton:hover {
  color: inherit;
}

.deleteIcon {
  padding-right: 5px;
}

.iconWrapper {
  display: flex;
}

.deleteBookedSlotConfirmationModalTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.deleteBookedSlotConfirmationModalActions {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-top: 20px;
}

.deleteBookedSlotConfirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.deleteBookedSlotConfirmationModalTitle {
  font-size: 19px;
  font-weight: bold;
  padding: 24px 24px 4px 24px;
}

.deleteBookedSlotConfirmationModalCancel {
  position: absolute;
  top: 20px;
  right: 20px;
}

.deleteBookedSlotConfirmationModalList {
  list-style-type: disc;
  margin-left: 25px;
  color: var(--palette-text-primary);
}

.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 20px 0px 20px;
}

.cancelMemberModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.oooMemberModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.deleteModalTitleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cancelMemberModalTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.oooModalTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.cancelModalTitle {
  font-size: 20px;
  font-weight: bold;
  padding: 0px 24px !important;
}

.deleteModalTitle {
  font-size: 16px;
  font-weight: bold;
}

.deleteModalCancel {
  position: absolute;
  right: 20px;
}

.oooModalCancel {
  position: absolute;
  right: 20px;
}

.deleteModalActions {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
}

.oooModalActions {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: baseline;
  padding-top: 20px;
}

.cancelMemberModalActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 0px;
}

.cancelMemberButton {
  margin: 24px 0px 0px !important;
}

.nevermindButton {
  margin: 24px 0px 0px !important;
  margin-left: 24px !important;
}

.datePicker {
  width: 40%;
  padding-left: 24px;
  padding-top: 8px;
}

.scheduleVisitTab {
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
  height: 100%;
  overflow-y: auto;
}

.scheduleVisitTitle {
  font-size: 16px;
  font-weight: bold;
}

.memberInformation {
  border-radius: 8px;
  background-color: var(--palette-app-background-main);
  padding: 10px 0px 16px 12px;
  color: var(--palette-dark-gray);
}

.memberInfoTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.memberInfoTitle a {
  color: var(--palette-text-link);
  font-weight: bold;
  text-decoration-color: inherit;
}

.memberInfoTitle p {
  font-weight: bold;
  color: var(--palette-text-primary);
}

.memberInfoTitle a:focus-visible {
  border: 1px solid var(--palette-text-link);
}

.memberInformationContainer {
  margin-bottom: 8px;
}

.scheduleMemberText {
  color: var(--palette-text-secondary);
  padding: 16px 24px;
}

.toggleButton {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  border: 1px solid var(--palette-secondary-main);
  background-color: var(--palette-app-background-bluePastelAlt);
  color: var(--palette-secondary-main);
}

.endDatePicker {
  width: 40%;
  padding-top: 8px;
}

.actionModalTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 24px 24px 24px 24px;
}

.actionModalActions {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-top: 20px;
}

.editScheudledSlotActions {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-top: 20px;
  padding-left: 0px;
}

.deleteModalConflictingActions {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-top: 20px;
}

.deleteModalConflictingList {
  list-style-type: disc;
  margin-top: 1rem;
  padding-left: 16px;
}

.deleteModalTitleConflicting {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.updateSlotSeriesWarningModalTitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  align-self: stretch;
}

.updateSlotSeriesWarningModalTitle {
  padding: 0 !important;
}

.listItem {
  padding: 8px 16px;
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.listItem:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.listItem:focus-visible {
  background-color: rgba(0, 0, 0, 0.08);
  outline: 2px solid #1976d2;
}

.optionLabel {
  margin-left: 8px;
}

.linkAConvoText {
  color: var(--palette-darkest-gray);
  padding-bottom: 8px;
}
