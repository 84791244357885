.badge {
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 1px;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 9.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  min-width: 8px;
  height: 12px;
}
