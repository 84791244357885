.underline {
  text-decoration: underline;
}

.headingText {
  margin-bottom: 4px;
}

.bodyText {
  color: var(--palette-text-secondary);
  margin-top: 0;
  margin-right: 5px;
  margin-bottom: 4px;
  display: inline;
  line-height: 1.3rem;
}

.tertiary {
  text-decoration: none;
  color: black;
}

.tertiary:hover {
  text-decoration: underline;
}

.small {
  margin-bottom: 2px;
  font-size: 13.3px;
}

.medium {
  margin-bottom: 2px;
  font-size: 16px;
}

.large {
  margin-bottom: 5.6px;
  font-size: 16px;
}

.container {
  margin-left: 2px;
}

.callSplit {
  margin-top: -5px;
}

.employer {
  display: flex;
}
