.eocActionableItems {
  display: flex;
  justify-content: space-between;
}

.eocActionableItems:not(:first-child) {
  padding-top: 15px;
}

.eocActionableItemsList {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.padding16 {
  padding-bottom: 16px;
}

.marginBottom4 {
  margin-bottom: 4px;
 }

.badge-tooltip {
  display: inline-flex;
}

.textSecondary {
  color: var(--palette-text-secondary);
}

.secondaryMain {
  color: var(--palette-secondary-main);
}

.flex {
  display: flex;
}

.activeItems {
  padding-right: 10px;
  color: var(--palette-primary-main);
}

.itemLink {
  text-decoration: none;
}