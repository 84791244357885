.paper {
  width: 300px;
  border-radius: 6px;
}

.titleContainer {
  padding-left: 16px;
  padding-right: 0px !important;
  padding-bottom: 0px;
  display: flex;
  align-items: flex-start;
}

.title {
  font-size: var(--typography-caption-font-size);
  color: var(--palette-app-background-navy);
  padding-top: 4px;
}

.contentContainer {
  padding-top: 0;
  padding-left: 8px;
  padding-bottom: 16px;
}

.noTeamAssigned {
  padding-bottom: 8px;
  font-weight: normal;
}
