.eocMember {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.vucBadge {
  margin-left: 4px;
  margin-top: -2px;
}

.duration {
  color: var(--palette-text-secondary);
  margin-bottom: 0;
}

.spaSpan {
  display: inline;
  position: relative;
  top: -5px;
  color: var(--palette-text-secondary);
}

.reason {
  padding-right: 4px;
  padding-bottom: 4px;
}

.badges {
  margin-top: 1px;
}

.marginBottom4 {
  margin-bottom: 4px;
}

.selectOwnerButton {
  padding-right: 4px;
  padding-left: 4px;
  color: var(--palette-text-secondary);
  text-decoration: underline;
}

.memberMessageIcon {
  color: #606a76;
  font-size: 16px;
}

.textPrimary {
  color: var(--palette-text-primary);
}

.textSecondary {
  color: var(--palette-text-secondary);
}

.ownerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.owner {
  margin: auto 0;
}

.titleContainer {
  display: flex;
}

.marginBottom0 {
  margin-bottom: 0;
}

.hoverableText {
  display: inline-block;
  text-decoration: underline;
  color: var(--palette-text-secondary);
  &:hover {
    cursor: pointer;
    color: var(--palette-text-primary);
  }
}
