.h1 {
  font-weight: bold;
  font-size: var(--typography-h1-font-size);
  line-height: var(--typography-h1-line-height);
}

.h2 {
  font-weight: bold;
  font-size: var(--typography-h2-font-size);
  line-height: var(--typography-h2-line-height);
}

.h3 {
  font-weight: bold;
  font-size: var(--typography-h3-font-size);
  line-height: var(--typography-h3-line-height);
}

.h4 {
  font-weight: bold;
  font-size: var(--typography-h4-font-size);
  line-height: var(--typography-h4-line-height);
}

.h5 {
  font-weight: bold;
  font-size: var(--typography-h5-font-size);
  line-height: var(--typography-h5-line-height);
}

.h6 {
  font-weight: 500;
  font-size: var(--typography-h6-font-size);
  line-height: var(--typography-h6-line-height);
}

.lead {
  font-weight: 400;
  font-size: var(--typography-lead-font-size);
  line-height: var(--typography-lead-line-height);
}

.subtitle1 {
  font-size: var(--typography-subtitle1-font-size);
  line-height: var(--typography-subtitle1-line-height);
}

.subtitle2 {
  font-weight: 400;
  font-size: var(--typography-subtitle2-font-size);
  line-height: var(--typography-subtitle2-line-height);
}

.bodyEmphasized {
  font-weight: 700;
  font-size: var(--typography-body1-font-size);
  line-height: var(--typography-body1-line-height);
}

.body {
  font-size: var(--typography-body1-font-size);
  line-height: var(--typography-body1-line-height);
}

.smallBody {
  font-size: var(--typography-body2-font-size);
  line-height: var(--typography-body2-line-height);
}

.bodySmall {
  font-size: var(--typography-body2-font-size);
  line-height: var(--typography-body2-line-height);
}

.button {
  font-size: 1rem;
  text-transform: none;
}

.caption {
  font-size: var(--typography-caption-font-size);
  line-height: var(--typography-caption-line-height);
}

.miniCaption {
  font-size: var(--typography-mini-caption-font-size);
  line-height: var(--typography-mini-caption-line-height);
}

.overline {
  font-weight: 500;
  font-size: var(--typography-overline-font-size);
  line-height: var(--typography-overline-line-height);
  text-transform: uppercase;
}

.fontWeightBold {
  font-weight: bold !important;
}

.fontWeightNormal {
  font-weight: normal;
}

.textDecorationUnderline {
  text-decoration: underline;
}

.focusStyle:focus[data-whatintent="keyboard"] {
  border: 2px solid #ca4004;
  border-radius: 5px;
}

.gutterBottom {
  margin-bottom: 0.35em;
}

@media (max-width: 768px) {
  .h1 {
    font-size: var(--typography-h1-mobile-font-size);
    line-height: var(--typography-h1-mobile-line-height);
  }

  .h2 {
    font-size: var(--typography-h2-mobile-font-size);
    line-height: var(--typography-h2-mobile-line-height);
  }

  .h3 {
    font-size: var(--typography-h3-mobile-font-size);
    line-height: var(--typography-h3-mobile-line-height);
  }

  .h4 {
    font-size: var(--typography-h4-mobile-font-size);
    line-height: var(--typography-h4-mobile-line-height);
  }

  .h5 {
    font-size: var(--typography-h5-mobile-font-size);
    line-height: var(--typography-h5-mobile-line-height);
  }

  .lead {
    font-size: var(--typography-lead-mobile-font-size);
    line-height: var(--typography-lead-mobile-line-height);
  }

  .subtitle1 {
    font-size: var(--typography-subtitle1-font-size);
    line-height: var(--typography-subtitle1-line-height);
  }

  .subtitle2 {
    font-weight: 400;
    font-size: var(--typography-subtitle2-font-size);
    line-height: var(--typography-subtitle2-line-height);
  }

  .bodyEmphasized {
    font-size: var(--typography-body1-mobile-font-size);
    line-height: var(--typography-body1-mobile-line-height);
  }

  .body {
    font-size: var(--typography-body1-mobile-font-size);
    line-height: var(--typography-body1-mobile-line-height);
  }

  .smallBody {
    font-size: var(--typography-body2-mobile-font-size);
    line-height: var(--typography-body2-mobile-line-height);
  }

  .bodySmall {
    font-size: var(--typography-body2-mobile-font-size);
    line-height: var(--typography-body2-mobile-line-height);
  }
}
