.visitNotesModalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 19px;
}

.visitNotesModalContent {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.tableHeader {
  font-size: 11.1px;
  padding: 6px 0px;
  color: var(--palette-common-darkGrey);
}

.tableHeaderRight {
  font-size: 13px;
  padding: 6px 0px;
}

.tableBodyRow {
  vertical-align: top;
}

.tableBody {
  font-size: 13.3px;
  padding: 16px 0px;
}

.systemNoteChip {
  background-color: var(--palette-common-borderGray);
  border-radius: 2px;
  padding: 2px;
  font-size: 8px;
  font-weight: 400;
}

.manualNoteChip {
  background-color: var(--palette-app-background-lightblue);
  border-radius: 2px;
  padding: 2px;
  font-size: 8px;
  font-weight: 400;
}

.note {
  font-size: 13.3px;
}

.authorName {
  font-size: 13.3px;
  font-weight: 400;
  line-height: 18px;
  color: var(--palette-text-primary);
}

.memberCaption {
  font-size: 16px;
  font-weight: 400;
  color: var(--palette-dark-gray);
}

.memberName {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: var(--palette-text-primary);
}

.memberInformation {
  border-radius: 8px;
  background-color: var(--palette-app-background-main);
  padding: 10px 0px 16px 12px;
  color: var(--palette-dark-gray);
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.addNoteContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.deleteNoteModalActions {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  height: 92px !important;
  padding: 0;
  justify-content: center;
}

.deleteModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--palette-text-secondary);
  justify-content: center;
  height: 250px;
  width: 408px;
}

.deleteModalTitleContainer {
  width: 100%;
  display: flex;
  padding: 24px 0;
  justify-content: center;
}

.deleteModalTitle {
  margin-bottom: 16px;
}

.deleteModalTitleColor {
  color: var(--palette-text-primary);
}

.deleteModalDialogContent {
  width: 100%;
  text-align: center;
  padding: 0 !important;
}

.deleteModalCancel {
  position: absolute;
  right: 20px;
}

.deleteModalActions {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
}

.deleteButton {
  height: 44px;
  border-radius: 24px;
}

.readMoreButton {
  margin-left: 0 !important;
}

.closeIcon {
  width: 24px;
  height: 24px;
  color: var(--palette-text-secondary);
}

.textArea {
  margin-top: 0px;
}

.deleteButton svg {
  color: var(--palette-text-secondary);
}

.dialog .MuiDialog-paper {
  width: auto;
}