.avatarContainer {
  justify-content: center;
  margin-bottom: 10px;
}

.avatarLink {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background: var(--app-background-lightest-gray);
  padding-top: 5px;
  padding-bottom: 5px;
}

.eventContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1px;
}

.titleDisplayContainer {
  padding: 1px 6px;
  margin: 3px;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 10px;
  height: 100%;
}

.titleAndDate {
  display: flex;
  justify-content: space-between;
}

.titleTruncation {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 65%;
}

.minuteText {
  color: var(--palette-text-primary);
}

.visibilityIcon {
  font-size: 14px;
  margin-right: 3px;
}

.visibilityIconError {
  font-size: 14px;
  margin-right: 3px;
  color: #ca4004;
}

.groupIconTextContainer {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}

.groupIconContainer {
  width: 20%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3f4dd;
}

.groupIconContainerFullGroup {
  width: 20%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff2f8;
}

.groupIcon {
  font-size: 10px;
  margin-right: 3px;
}

.providerName {
  font-size: 0.8rem;
  font-weight: lighter;
}

.eventContainerMirror {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: #c28995 !important;
  border-radius: 0px;
  border: 0px;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eventDisplayLabel {
  background-color: #eeeff0;
  border-radius: 4px;
  padding: 2px 4px;
  margin-right: 3px;
}
