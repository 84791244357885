.paper {
  width: 552px;
}

.titleContainer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.title {
  font-size: 19.2px !important;
  color: var(--palette-text-primary);
}

.dialogContent {
  padding-top: 4px !important;
  width: 100%;
}

.subHead {
  color: var(--palette-text-secondary);
  padding-bottom: 16px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 16px;
}

.closeIcon {
  color: var(--palette-text-secondary);
  margin-top: 16px;
  margin-left: auto;
  margin-right: 16px;
  cursor: pointer;
}

.memberInfoBorder {
  border: 1px solid var(--palette-text-tertiary);
  border-radius: 10px;
}

.memberInfo {
  padding: 12px 16px;
  color: var(--palette-text-secondary);
}

.documentAlreadyExists {
  margin-top: 24px;
  width: 100%;
  height: 169px;
}

.dragAndDropBorder {
  margin-top: 24px;
  width: 100%;
  height: 169px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed var(--palette-text-secondary);
  background: var(--palette-app-background-main);
}

.dragAndDropContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dragAndDropText {
  color: var(--palette-text-primary);
  text-align: center;
}

.dragAndDropSupported {
  padding-top: 8px;
  font-weight: 400;
  color: var(--palette-text-secondary);
  text-align: center;
}

.browseButton {
  padding: 0 !important;
}

.browseText {
  color: var(--palette-text-link);
  text-decoration: underline;
  text-align: center;
  line-height: 1.4rem !important;
}

.dateOfConsentText {
  padding-top: 24px;
  padding-bottom: 8px;
  color: var(--palette-text-primary);
}

.dateContainer {
  width: 60%;
  border-bottom: 2px solid var(--palette-text-primary);
  border-radius: 10px 10px 0 0;
  outline: none;
  background: var(--palette-app-background-lightestGrayHover);
  transition: ease-in 0.3s;
  color: var(--palette-text-primary);
  cursor: pointer;
  margin-top: 8px;
}

.dateHelperText {
  color: #ca4004;
  margin-top: 4px;
}

.uploadIcon {
  justify-content: center;
  width: 65.54px;
  height: 64px;
  flex-shrink: 0;
  fill: var(--palette-text-secondary);
}

.uploadingStatus {
  padding-top: 24px;
}

.uploadInfoContainer {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
}

.uploadedFileNameAndStatus {
  display: flex;
}

.uploadedFileName {
  color: var(--palette-text-primary);
  padding-right: 16px;
}

.uploadCheckIcon {
  width: 20px;
  height: 20px;
  fill: var(--palette-accent-main);
  padding-right: 2px;
}

.uploadDoneStatus {
  color: var(--palette-text-secondary);
  margin-top: 4px;
}

.deleteButton {
  padding: 0px !important;
  min-width: auto;
}

.deleteIcon {
  width: 20px;
  height: 20px;
  fill: var(--palette-text-secondary);
}

.pdfIcon {
  width: 26px;
  height: 24px;
  fill: var(--palette-text-secondary);
  padding-right: 4px;
}

.emailField {
  width: 100%;
  margin-top: 16px !important;
}
