.appBar {
  background-color: var(--palette-common-white);
  height: 60px;
}

.logo {
  margin-top: 16px;
  margin-left: 16px;
  height: 30px;
}

.container {
  background-color: var(--palette-app-background-main);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 110px;
  width: 439px;
}

@media screen and (max-width: 767px) {
  .textContainer {
    width: 329px;
    padding-top: 60px;
  }
}

.errorMessage {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.9px;
  color: var(--palette-text-primary);
  padding-bottom: 24px;
}

@media (max-width: 767px) {
  .errorMessage {
    font-size: 14px;
    line-height: 18.2px;
  }
}

.image {
  height: 240px;
  width: 240px;
}

@media screen and (max-width: 767px) {
  .image {
    height: 215px !important;
    width: 215px !important;
  }
}
