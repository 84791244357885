.container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.labelContainer {
  width: 100%;
}

.iconContainer {
  flex-shrink: 1;
}

.plusIcon {
  background-color: var(--palette-accent-main);
  color: var(--palette-common-white);
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer !important;
}

.badges {
  display: flex;
  align-items: center;
  gap: 5px;
}

.button {
  min-width: auto !important;
  padding-right: 0px !important;
}

.button:hover {
  background-color: transparent !important;
}

.proxyIcon {
  color: var(--palette-common-white);
  background-color: transparent;
  border-radius: 50%;
  width: 22px !important;
  height: 22px !important;
  border-color: none;
  cursor: pointer;
}
