.duration {
  color: var(--palette-text-secondary);
  margin-bottom: 0;
}

.highlights {
  padding: 0px 61px 2px 8px;
  height: 100%;
  flex: 1;
}

.padding8 {
  padding: 8px;
}

.tableRowItem {
  display: flex;
  padding: 8px;
}

.aIRadioButtonContainer {
  width: 40px;
  padding: 8px;
}

.aIOwnershipIcon {
  margin: 0 !important;
}

.font {
  font-size: var(--typography-body2-font-size);
}

.arrow {
  position: relative;
  color: var(--palette-text-tertiary);
  height: 18px;
  width: 18px;
}

.lineItem {
  width: 100%;
  align-items: stretch;
  border-top: 1px solid var(--palette-strokes-divider);
}

.lineItem:hover {
  background: var(--palette-app-background-main);
}

.lineItem:hover .icon-chevron {
  fill: var(--palette-primary-main);
  left: 5px;
}

.lineItem:hover:first-child {
  border: none;
}

.lineItemSection {
  flex: 1;
  align-items: stretch;
  display: flex;
  padding: 8px;
  border-right: 1px solid var(--palette-strokes-divider);
}

.columnHeaderContainer {
  width: 100%;
  align-items: stretch;
  border-top: 1px solid var(--palette-strokes-divider);
}

.columnHeaderArchived {
  padding: 8px 61px 2px 8px;
}

.timeSinceContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconContainer {
  height: auto;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.timeSinceContainer {
  background-color: var(--palette-danger-main);
  justify-content: center;
}

.timeSensitive {
  color: var(--palette-text-primary);
}

.eocLineItemTable {
  border-bottom: 1px solid var(--palette-strokes-tertiary);
  margin-bottom: 40px;
}

.icon-chevron {
  fill: var(--palette-strokes-tertiary);
  position: relative;
  transition: all ease-in-out 0.3s;
  height: 24px;
  width: 24px;
}

.actionableItemsArrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badgeContainer {
  margin-top: 4px;
}

.text-align {
  text-align: left;
  margin-bottom: 4px;
}

.highlightsHeader {
  text-align: left;
  margin-bottom: 4px;
}

.text-secondary {
  color: var(--palette-text-secondary);
}

.layout {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr minmax(min-content, 4fr) 40px;

  grid-template-areas: "time member convo items button";
  grid-template-rows: auto;
}

.gridAreaTime {
  grid-area: time;
}

.gridAreaMember {
  grid-area: member;
}

.gridAreaConvo {
  grid-area: convo;
}

.gridAreaItems {
  grid-area: items;
}

.gridAreaButton {
  grid-area: button;
}

.user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 3px;
}

.archivedIcon {
  color: var(--palette-text-secondary);
  margin-top: 16px;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 575px) {
  .aIRadioButtonContainer {
    padding: 2px;
  }

  .lineItem {
    justify-content: start;
  }

  .columnHeaderContainer {
    justify-content: start;
  }

  .layout {
    grid-template-columns: 1fr 4fr 4fr 40px;
    grid-template-areas: "time member convo button";
  }

  .gridAreaItems {
    display: none;
  }
}
