.paymentsOwedModalTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 19px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.paymentsOwedModalContent {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.tableHeader {
  font-size: 11.1px;
  padding: 0;
  color: var(--palette-dark-gray);
}

.tableHeaderRight {
  font-size: 11.1px;
  color: var(--palette-dark-gray);
  padding: 0px;
}

.tableBodyRow {
  vertical-align: top;
  padding: 6px 0px;
}

.tableBody {
  font-size: 13.3px;
  padding: 12px 0;
}

.tableBodyOffset {
  font-size: 13.3px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.paymentsOwedItem {
  font-size: 13.3px;
  line-height: 18px;
}

.paymentsOwedItemDescription {
  font-size: 13.3px;
  color: var(--palette-common-darkGrey);
  font-weight: normal;
  font-size: var(--typography-small-body-font-size);
  line-height: var(--typography-small-body-line-height);
}

.dateCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.tableRow {
  font-size: 13.3px;
  line-height: 17.29px;
}

.memberCaption {
  font-size: 12px;
  font-weight: 400;
  color: var(--palette-dark-gray);
}

.memberName {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: var(--palette-text-primary);
}

.memberInformation {
  border-radius: 8px;
  background-color: var(--palette-app-background-main);
  padding: 10px 0px 16px 12px;
  color: var(--palette-dark-gray);
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.closeIcon {
  width: 24px;
  height: 24px;
  color: var(--palette-text-secondary);
}

.textArea {
  margin-top: 0px;
}
