.accent {
  color: var(--palette-common-white);
}

.avatarContainer {
  margin-left: -16px;
}

.memberProfileLink {
  padding: 0 !important;
}
