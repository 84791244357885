.paper {
  max-width: 552px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  padding-top: 24px;
  font-size: 19.2px !important;
  color: var(--palette-text-primary);
}

.closeIcon {
  color: var(--palette-text-secondary);
  cursor: pointer;
  margin: 0 16px;
}

.dialogContent {
  padding-top: 0px !important;
}

.subtitle {
  color: var(--palette-text-secondary);
  padding-top: 0px;
  padding-bottom: 16px;
  font-size: 13.3px;
  line-height: 17px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 16px;
}

.memberInfoBorder {
  border: 1px solid var(--palette-text-tertiary);
  border-radius: 10px;
}

.memberInfo {
  padding: 12px 16px;
  color: var(--palette-text-secondary);
}

.email {
  margin-top: 16px;
  margin-bottom: 8px;
}

.helperText {
  padding-bottom: 16px;
}

.address {
  margin-top: 24px;
}

.addressCaption {
  color: var(--palette-text-secondary);
  padding-bottom: 8px;
  padding-top: 4px;
}

.addressText {
  font-weight: 200;
  padding-bottom: 8px;
}

@media (max-width: 768px) {
  .addressCaption {
    width: 100%;
  }
}

.proxyOption {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.proxyOption > span {
  padding-left: 0px !important;
}

.tooltip {
  color: var(--palette-text-secondary);
  margin-left: 8px;
}

.checkboxText {
  color: var(--palette-text-secondary);
  font-size: 13.3px;
  line-height: 17px;
  padding-bottom: 16px;
}
