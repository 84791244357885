.memberCard {
  background-color: var(--palette-app-background-main);
  padding: 16px 12px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.memberSecondaryInfo {
  margin-bottom: 16px;
}
.greyText {
  color: var(--palette-text-secondary);
}
