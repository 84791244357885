.loadingSpinnerContainer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1000;
  height: 60px;
  display: flex;
  justify-content: center;
}

.spinner {
  color: var(--palette-text-secondary);
}
