.hold > .group:not(.maxBooked) {
  background-color: rgba(180, 122, 133, 1) !important;
}

/* // this is causing the problem */
.hold > :not(.group) > :not(.maxBooked) {
  background-color: rgba(180, 122, 133, 1) !important;
}

.internal {
  position: relative;
}

.fc-highlight {
  background-color: rgba(180, 122, 133, 0.4) !important;
}

.creating {
  background-color: rgba(180, 122, 133, 1) !important;
  border: 1px solid rgba(180, 122, 133, 1) !important;
}

/* Keyframes for the spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.openSlot {
  background: repeating-linear-gradient(
    315deg,
    transparent,
    transparent 5px,
    rgba(0, 0, 0, 0.2) 5px,
    rgba(0, 0, 0, 0.2) 10px
  ) !important;
}

.group > .internalSlot:not(.maxBooked) {
  background-color: #b47a85 !important;
  /* private slots */
}

.internalSlot.openSlot {
  background-color: #b47a85 !important;
  /* private slots */
}

.fc-timegrid-axis {
  border: none !important;
}

.fc-timegrid-slot {
  height: 11px !important;
  line-height: 11px !important;
}

.fc-scrollgrid {
  border: none !important;
}

.fc-timegrid-axis-frame:first-of-type {
  text-wrap: nowrap;
}

.fc-timegrid-axis-frame {
  text-align: left;
  margin-right: 5px;
  overflow-y: auto;
  height: 100px;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: white;
}

.fc-col-header-cell {
  border-bottom: 1px solid var(--palette-common-borderGray) !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  min-width: 140px !important;
}

.fc .fc-col-header-cell {
  width: 212px !important;
}

.fc-timegrid-col {
  width: 212px !important;
}

#first-time-label {
  display: none;
}

.fc-timegrid-slot-label-cushion {
  position: relative;
  top: -7px;
}

.fc-timegrid-slots .fc-timegrid-slot-minor {
  border-top: 1px solid transparent !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}

.fc-theme-standard td {
  border-top: 1px dotted var(--palette-common-borderGray) !important;
  border-right: 1px solid var(--palette-common-borderGray) !important;
}

.fc-col-header-cell {
  background: #f7f7f8;
}

.fc .fc-timegrid-slot-label {
  vertical-align: top;
  border-top: 1px solid var(--palette-common-borderGray) !important;
}

.fc .fc-createSlotButton-button {
  padding: 4px 16px 4px 16px;
  margin-left: 8px !important;
  border-radius: 32px !important;
  background-color: transparent;
  color: var(--palette-text-primary);
  border-color: var(--palette-primary-main);
}

/* this element shouldn't show in prod but is setting off a color contrast violation in automated scans
correct it so that we can run our color contrast scan on the whole page */
.fc-license-message > a {
  color: #b80000;
}

.xo-cal-sidebar .MuiSelect-select {
  padding: 8px;
  background-color: white;
  border: 1px solid var(--palette-common-borderGray);
  border-radius: 8px;
  white-space: normal;
}

.xo-cal-sidebar .MuiSelect-select:focus-visible {
  outline: 2px solid var(--palette-text-link) !important;
}

.xo-cal-sidebar .MuiFormLabel-root {
  top: -10px;
  left: -10px;
  color: var(--palette-text-secondary);
}

.MuiOutlinedInput-notchedOutline {
  border: none;
}

.checkInPending {
  position: relative;
}

.checkInPending::before {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background-color: rgba(101, 66, 130, 1);
  position: absolute;
  left: -1px;
  top: 0;
}

.checkInCompleted {
  position: relative;
}

.checkInCompleted::before {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background-color: rgba(87, 134, 225, 1);
  position: absolute;
  left: -1px;
  top: 0;
}

.fc-event {
  border: none;
  padding: 0;
}

.xocal-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.xocal-calendar {
  height: 100%;
  width: 100%;
  transition: opacity 0.3s ease;
}

.xocal-calendar--loading {
  opacity: 0;
  pointer-events: none;
}

.xocal-skeleton-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.xocal-skeleton-overlay--visible {
  opacity: 1;
  visibility: visible;
}

.xocal-no-provider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.xocal-no-provider-overlay--visible {
  opacity: 1;
  visibility: visible;
}

.xocal-calendar .fc {
  height: 100% !important;
}

.checkInPending::before,
.checkInCompleted::before,
.group::before,
.openSlot::before,
.booked::before,
.bookedHoldSlot::before,
.conflicted::before {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
}

.checkInPending::before {
  background-color: rgba(101, 66, 130, 1);
}

.checkInCompleted::before {
  background-color: rgba(87, 134, 225, 1);
}

.group:not(.openHoldGroupSlot)::before {
  background-color: rgba(250, 214, 73, 1);
}

.openSlot:not(.partiallyBooked)::before {
  background-color: #aafa4e;
}

.booked::before {
  background-color: #f29d38;
}

.bookedHoldSlot::before {
  background-color: #f29d38;
}

.conflicted::before {
  background-color: #ca4004;
}

.checkInPending,
.checkInCompleted,
.group,
.internal,
.booked,
.conflicted {
  position: relative;
}

/* OOO and Clear Day Modal Calendar Custom Styling*/

.rmdp-header-values {
  font-weight: bold !important;
  color: #2b3949 !important;
}

.rmdp-header-values span {
  padding: 0px !important;
}

.ooo-background {
  background-color: #ffe9c9 !important;
}

.ooo-badge {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin: 4px;
}

.ooo-badge-words {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ooo-words-dark {
  color: #2b3949;
  padding-left: 4px;
}

.ooo-words-light {
  color: #606a76;
  padding-right: 4px;
}

.b-date {
  background-color: #4c5a73 !important;
}

.b-deselect {
  background-color: #4c5a73 !important;
}

.rmdp-panel-body li {
  border-radius: 15px !important;
}

.rmdp-panel-body li .b-date {
  font-size: 12px !important;
}

.rmdp-range {
  background-color: #4c5a73 !important;
}

.rmdp-range span {
  color: white !important;
}

.rmdp-range-hover.start:not(.force),
.rmdp-range.start:not(.force) {
  border-radius: 10px 0px 0px 10px !important;
}

.rmdp-range-hover.end:not(.force),
.rmdp-range.end:not(.force) {
  border-radius: 0px 10px 10px 0px !important;
}

.rmdp-day.rmdp-today span {
  background-color: #1c74ab !important;
  border-radius: 4px !important;
}

.rmdp-week-day {
  color: #606a76 !important;
}

.rmdp-day {
  color: #2b3949 !important;
}

.rmdp-day.rmdp-deactive,
.rmdp-day.rmdp-disabled {
  color: #838b95 !important;
}

.rmdp-arrow {
  border: solid #606a76 !important;
  border-width: 0px 2px 2px 0px !important;
}

.rmdp-arrow-container .rmdp-left {
  padding-left: 3px !important;
}

.rmdp-arrow-container {
  padding-top: 2px !important;
}

.rmdp-arrow-container .rmdp-right {
  padding-left: 0px !important;
}

.rmdp-arrow-container:hover {
  background-color: #606a76 !important;
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid white !important;
  border-width: 0px 2px 2px 0px !important;
}

/* Styling from React-Multi-Date-Picker from the Calendar and Date Panel Components */
/* Styles were disappearing in production environment, so copied and pasted the CSS here */
/* https://github.com/shahabyazdi/react-multi-date-picker */
/* https://www.npmjs.com/package/react-multi-date-picker - version 4.5.2 */

:root {
  --rmdp-primary: #0074d9;
  --rmdp-secondary: #4ca6f5;
  --rmdp-shadow: #8798ad;
  --rmdp-today: #7fdbff;
  --rmdp-hover: #7ea6f0;
  --rmdp-border: #cfd8e2;
  --highlight-red-color: #cc0303;
  --highlight-red-color-deactive: #e08e8e;
  --highlight-red-selected: #ea0034;
  --highlight-red-selected-deactive: #e4b0ba;
  --highlight-red-hover: #ff6687;
  --highlight-green-color: #00796b;
  --highlight-green-color-deactive: #7ab3ac;
  --highlight-green-selected: #009688;
  --highlight-green-selected-deactive: #749c98;
  --highlight-green-hover: #4db6ac;
}

.rmdp-wrapper {
  width: max-content;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  direction: ltr;
}

.rmdp-shadow {
  box-shadow: 0 0 5px var(--rmdp-shadow);
}

.rmdp-border {
  border: 1px solid var(--rmdp-border);
}

.rmdp-calendar {
  padding: 4px;
  height: max-content;
}

.rmdp-border-top {
  border-top: 1px solid var(--rmdp-border);
}

.rmdp-border-bottom {
  border-bottom: 1px solid var(--rmdp-border);
}

.rmdp-border-left {
  border-left: 1px solid var(--rmdp-border);
}

.rmdp-border-right {
  border-right: 1px solid var(--rmdp-border);
}

.rmdp-week,
.rmdp-ym {
  display: flex;
  justify-content: space-between;
}

.rmdp-ym {
  height: 25%;
}

.rmdp-day,
.rmdp-week-day {
  height: 34px;
  width: 34px;
  cursor: pointer;
  position: relative;
  color: black;
}

.rmdp-calendar *:focus {
  outline-color: #00539c;
}

.rmdp-day:not(.rmdp-range):focus {
  border-radius: 50%;
}

.rmdp-ym .rmdp-day:not(.rmdp-range):focus {
  border-radius: 15px;
}

.rmdp-week-day {
  cursor: default;
  color: var(--rmdp-primary);
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
}

.rmdp-day span {
  position: absolute;
  left: 3px;
  right: 3px;
  top: 3px;
  bottom: 3px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}

.rmdp-day.rmdp-today span {
  background-color: var(--rmdp-today);
  color: white;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--rmdp-primary);
  box-shadow: 0 0 3px var(--rmdp-shadow);
  color: white;
}

.rmdp-day.rmdp-deactive,
.rmdp-day.rmdp-disabled {
  color: var(--rmdp-shadow);
}

.rmdp-day.rmdp-deactive.rmdp-selected span {
  background-color: var(--rmdp-secondary);
  box-shadow: 0 0 3px #bac5d3;
}

.rmdp-ym .rmdp-day {
  flex: 1;
  margin: auto;
}

.rmdp-ym .rmdp-day span {
  border-radius: 12px;
  padding: 2px 0px;
}

.rmdp-range {
  color: white;
  background-color: var(--rmdp-primary);
  box-shadow: 0 0 3px var(--rmdp-shadow);
}

.rmdp-range-hover {
  background-color: var(--rmdp-hover);
  color: white;
}

.rmdp-range.start:not(.force),
.rmdp-range-hover.start:not(.force) {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rmdp-range.end:not(.force),
.rmdp-range-hover.end:not(.force) {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rmdp-ym .rmdp-range.start:not(.force),
.rmdp-ym .rmdp-range-hover.start:not(.force) {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rmdp-ym .rmdp-range.end:not(.force),
.rmdp-ym .rmdp-range-hover.end:not(.force) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media (hover: hover) {
  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: var(--rmdp-hover);
    color: white;
  }
}

.rmdp-day-picker {
  padding: 5px;
}

.rmdp-header {
  margin-top: 5px;
  font-size: 14px;
  padding: 9px 0;
}

.rmdp-month-picker,
.rmdp-year-picker {
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 0 0 5px 5px;
}

.only.rmdp-month-picker,
.only.rmdp-year-picker {
  position: static;
  height: 240px;
  width: 240px;
}

.rmdp-header-values {
  color: black;
  margin: auto;
}

.rmdp-header-values span {
  padding: 0 5px;
  padding-right: 0;
}

.rmdp-arrow {
  border: solid var(--rmdp-primary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  height: 3px;
  width: 3px;
  margin-top: 5px;
}

.rmdp-right i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 3px;
}

.rmdp-left i {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 3px;
}

.rmdp-left {
  left: 0;
}

.rmdp-right {
  right: 0;
}

.rmdp-arrow-container {
  cursor: pointer;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  background: transparent;
  border: none;
  padding: 0;
}

.rmdp-arrow-container:hover {
  background-color: var(--rmdp-primary);
  box-shadow: 0 0 3px var(--rmdp-shadow);
}

.rmdp-arrow-container:hover .rmdp-arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
}

.rmdp-arrow-container.disabled {
  cursor: default;
}

.rmdp-arrow-container.disabled:hover {
  background-color: inherit;
  box-shadow: inherit;
}

.rmdp-arrow-container.disabled .rmdp-arrow,
.rmdp-arrow-container.disabled:hover .rmdp-arrow {
  border: solid gray;
  border-width: 0 2px 2px 0;
}

.rmdp-rtl {
  direction: rtl;
}

.rmdp-rtl .rmdp-left i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 0;
  margin-right: 3px;
}

.rmdp-rtl .rmdp-right i {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 0;
  margin-left: 3px;
}

.rmdp-rtl .rmdp-right {
  right: auto;
  left: 0;
}

.rmdp-rtl .rmdp-left {
  left: auto;
  right: 0;
}

.rmdp-rtl .rmdp-range.start:not(.force),
.rmdp-rtl .rmdp-range-hover.start:not(.force) {
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rmdp-rtl .rmdp-range.end:not(.force),
.rmdp-rtl .rmdp-range-hover.end:not(.force) {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rmdp-rtl .rmdp-range.start.end:not(.force) {
  border-radius: 50%;
}

.rmdp-rtl .rmdp-ym .rmdp-range.start:not(.force),
.rmdp-rtl .rmdp-ym .rmdp-range-hover.start:not(.force) {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.rmdp-rtl .rmdp-ym .rmdp-range.end:not(.force),
.rmdp-rtl .rmdp-ym .rmdp-range-hover.end:not(.force) {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rmdp-day-hidden,
.rmdp-day.rmdp-disabled {
  cursor: default;
}

.rmdp-selected .highlight {
  box-shadow: 0 0 3px var(--rmdp-shadow);
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-red:hover {
  background-color: var(--highlight-red-hover);
}

.rmdp-day:not(.rmdp-deactive) .highlight-red {
  color: var(--highlight-red-color);
}

.rmdp-day.rmdp-deactive .highlight-red {
  color: var(--highlight-red-color-deactive);
}

.rmdp-day.rmdp-selected .highlight-red {
  background-color: var(--highlight-red-selected);
  color: white;
}

.rmdp-day.rmdp-deactive.rmdp-selected .highlight-red {
  background-color: var(--highlight-red-selected-deactive);
  color: white;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) .highlight-green:hover {
  background-color: var(--highlight-green-hover);
}

.rmdp-day:not(.rmdp-deactive) .highlight-green {
  color: var(--highlight-green-color);
}

.rmdp-day.rmdp-deactive .highlight-green {
  color: var(--highlight-green-color-deactive);
}

.rmdp-day.rmdp-selected .highlight-green {
  background-color: var(--highlight-green-selected);
  color: white;
}

.rmdp-day.rmdp-deactive.rmdp-selected .highlight-green {
  background-color: var(--highlight-green-selected-deactive);
  color: white;
}

.rmdp-day-hidden:hover span,
.rmdp-day-hidden {
  background-color: unset;
  color: transparent;
}

.rmdp-month-name {
  font-size: 14px;
  margin: 3px 0;
  cursor: default;
}

.rmdp-full-year {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 450px), (max-height: 450px) {
  .rmdp-day,
  .rmdp-week-day {
    height: 28px;
    width: 28px;
  }

  .rmdp-day span {
    padding-left: 0.5px;
    font-size: 12px;
  }

  .only.rmdp-year-picker,
  .only.rmdp-month-picker {
    width: 230px;
    height: 200px;
  }

  .rmdp-header {
    padding: 3px 0 0 0;
    font-size: 12px;
  }

  .rmdp-month-name {
    font-size: 12px;
  }

  .rmdp-full-year {
    grid-template-columns: 1fr 1fr;
  }
}

:root {
  --rmdp-primary: #0074d9;
  --rmdp-shadow: #8798ad;
  --highlight-red-selected: #ea0034;
  --highlight-green-selected: #009688;
}

.rmdp-panel {
  min-width: 125px;
}

.rmdp-panel-body {
  position: absolute;
  left: 0;
  right: 0;
  list-style: none;
  padding: 0px 5px;
  margin: 0;
  text-align: center;
  overflow: auto;
}

.rmdp-panel-body li {
  border-radius: 3px;
  box-shadow: 0 0 2px var(--rmdp-shadow);
  background-color: var(--rmdp-primary);
  position: relative;
  margin: 4px 1px;
  overflow: hidden;
}

.rmdp-panel-body li.bg-blue {
  background-color: #0074d9;
}

.rmdp-panel-body li.bg-red {
  background-color: var(--highlight-red-selected);
}

.rmdp-panel-body li.bg-green {
  background-color: var(--highlight-green-selected);
}

.rmdp-panel-body li.bg-yellow {
  background-color: #fad817;
}

.rmdp-panel-body li.rmdp-focused {
  box-shadow: 0 0 2px 1px var(--rmdp-shadow);
}

.rmdp-panel-body li button {
  color: #fff;
  cursor: pointer;
}

.rmdp-panel-body li .b-deselect {
  border: none;
  background-color: var(--rmdp-primary);
  font-size: 17px;
}

.rmdp-panel-body li .b-deselect span {
  transform: rotate(45deg);
  display: block;
}

.rmdp-panel-body li div {
  flex: 1;
  display: grid;
}

.rmdp-panel-body li .b-date {
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding: 4px;
}

.panel {
  margin-top: 8px;
  height: 38px;
  line-height: 37px;
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
}

.rmdp-panel-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.rmdp-panel-body::-webkit-scrollbar-thumb {
  background: var(--rmdp-primary);
  border-radius: 3px;
}

.rmdp-panel-body::-webkit-scrollbar-thumb:hover {
  background: #0263b8;
}

.rmdp-rtl .rmdp-panel-body li .b-deselect {
  right: unset;
  left: 5px;
}

.rmdp-flat .rmdp-panel-header {
  border-bottom: none;
  margin-top: 8px;
}

.rmdp-flat .rmdp-panel-body li {
  border-radius: 0;
}

@media (max-width: 400px), (max-height: 400px) {
  .rmdp-panel {
    min-width: 103px;
  }

  .rmdp-panel-body {
    padding: 0 2px;
  }

  .rmdp-panel-body li {
    font-size: 12px;
    margin: 4px;
  }

  .rmdp-panel-header {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .rmdp-panel-body span {
    font-size: 12px;
  }

  .rmdp-panel-body li .b-date {
    font-size: 12px;
  }
}

/* Tooltip Event Content */

.MuiTooltip-tooltip {
  background-color: #f3f6f8;
  color: #606a76;
  max-width: none;
}
