.dateField {
  /* padding: 6px 12px 6px 12px; */
}

.dateFieldInput {
  border: 1px solid var(--palette-strokes-tertiary) !important;
  height: 2.5rem;
  border-radius: 10px;
  padding: 0;
}

.dateFieldInputError {
  border: 1px solid var(--palette-error-dark) !important;
  height: 2.5rem;
  border-radius: 10px;
  padding: 0;
  color: var(--palette-error-dark);
}

.pickersLayout {
  color: var(--palette-text-primary);
  border-radius: 10px !important;
  border: "0px solid";
}

.popper {
  border-radius: 10px !important;
}

.container {
  display: flex;
  align-items: center;
}

.errorText {
  padding-left: 3px;
}

.errorMessageContainer {
  font-size: small;
  padding-left: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: var(--palette-error-dark);
}
