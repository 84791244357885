.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--palette-app-background-lightestGrayHover);
  padding: 12px;
  border-radius: 6px;
}

.text {
  color: var(--palette-text-secondary);
}

.icon {
  margin-right: 8px;
  color: var(--palette-text-secondary);
}

.checkin {
  background-color: #ffe9c9;
}
