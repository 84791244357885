.root {
  display: flex;
  /* background-color: var(--palette-app-background-main); */
  height: 100%;
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 48px;
}

.image {
  width: 314px;
  padding-right: 30px;
}

.textContainer {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;
}

@media screen and (max-width: 575px) {
  .textContainer {
    max-width: 300px;
  }
}

.linkNotValid {
  padding-top: 24px;
  padding-bottom: 8px;
}

.goToHomePageLink {
  color: var(--palette-text-primary) !important;
  background-color: var(--palette-primary-main) !important;
}
